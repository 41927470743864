<template>
  <div>
    <breadcrumb-button>

      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <filter-card :filter-key="viewKey" v-model="filter" v-bind:fields="filterFields"
                 v-bind:hidden-fields="{'buTypes': viewKey!=='sales-dashboard' }"
    />

    <calendar-bar v-model="dateRange" @input="refresh"/>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.dashboard') }}</h5>
      </b-card-header>
      <b-card-body>
        <affiliate-dashboard-table ref="affiliateDashboardTable" v-bind:filters="filter"
                                   v-bind:columns="columns" :has-selection="false" @calculate="setAffiliatesData"
                                   :lazy="true"
        />
      </b-card-body>
    </b-card>
    <b-row>
      <b-col cols="6">
        <b-card no-body>
          <b-card-header class="pb-50">
            <h5>{{ $t('menu.dashboard') }}</h5>
          </b-card-header>
          <b-card-body>
            <status-dashboard-table ref="affiliateDashboardTable" v-bind:filters="filter"
                                    v-bind:columns="columns" :has-selection="false"
                                    :lazy="true"
                                    :value="statusMapCount"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <columns-modal ref="columns-modal" column-key="clientStatus" v-model="columns"
                   v-bind:columns-names="columnsNames"
    />

  </div>
</template>

<script>
import CalendarBar from '@/components/widget/CalendarBar';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal';
import mixinBase from '@/components/mixin/mixinBase';
import { mapActions, mapGetters } from 'vuex';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import FilterCard from '@/components/widget/FilterCard';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import UserSalesBarChart from '@/components/chart/UserSalesBarChart.vue';
import CountryDepositsBarChart from '@/components/chart/CountryDepositsBarChart.vue';
import ClientStatusPieChart from '@/components/chart/ClientStatusPieChart.vue';
import ClientCountryPieChart from '@/components/chart/ClientCountryPieChart.vue';
import AffiliateDashboardFilterFields from '@/model/Dashboard/AffiliateDashboardFilterFields';
import ClientStatusTable from '@/components/tables/ClientStatusTable.vue';
import AffiliateDashboardTable from '@/components/tables/AffiliateDashboardTable.vue';
import AffiliateDashboardSearchRequest from '@/model/Dashboard/AffiliateDashboardSearchRequest';
import AffiliateDashboard from '@/model/affiliate/AffiliateDashboard';
import ColumnsModal from '@/components/modal/columnsModal.vue';
import BreadcrumbButton from '@/components/widget/breadcrumbButton.vue';
import StatusDashboardTable from '@/components/tables/StatusDashboardTable.vue';

export default {
  name: 'AffiliateDashboard',
  mixins: [mixinBase, mixinNotifications, mixinPermissions],

  components: {
    BreadcrumbButton,
    ColumnsModal,
    AffiliateDashboardTable,
    ClientStatusTable,
    ClientCountryPieChart,
    ClientStatusPieChart,
    CountryDepositsBarChart,
    UserSalesBarChart,
    CalendarBar,
    FilterCard,
    StatisticCardHorizontal,
    StatusDashboardTable
  },

  data: () => ({
    filter: AffiliateDashboardSearchRequest(),
    columnsNames: Object.keys(AffiliateDashboard()),
    columns: [],
    statusMapCount: {},
    filterFields: [],
    dateRange: {
      from: '',
      until: ''
    },
    loading: false,

  }),
  watch: {},
  computed: {
    ...mapGetters('appConfig', ['isDarkSkin']),
    viewKey() {
      return this.$route.name || 'sales-dashboard';
    },

  },
  created() {
    this.filterFields = AffiliateDashboardFilterFields();
    this.refreshData();
  },
  methods: {
    ...mapActions('dashboard', ['getAffiliateDashboard']),
    ...mapActions('clients', ['refreshData']),
    setAffiliatesData(affiliates) {
      this.statusMapCount = affiliates.reduce((acc, affiliate) => {
        // Iterate through each affiliate's statuses map
        for (let [status, count] of Object.entries(affiliate.statuses)) {
          // Accumulate the count for each status
          if (acc[status]) {
            acc[status] += count;
          } else {
            acc[status] = count;
          }
        }
        return acc;
      }, {});
    },
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    refresh() {
      let $this = this;

      this.filter.range = {
        from: $this.dateRange.from,
        until: $this.dateRange.until
      };
    },
  }
};
</script>

<style scoped>
.dark-layout .apexcharts-title-text {
  background-color: rgba(255, 255, 255, 0);
  color: white !important;
}
</style>

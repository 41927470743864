import { vue } from '@/main'

export default function () {
  return [

    {
      key: 'affiliateIds',
      type: 'affiliate',
      label: vue.$t('affiliate'),
      props: {
        clearable: true,
        multiple: true
      }
    }

  ]
}

export default function (other) {
  other = other || {}
  return {
    company: other.company || null,
    leads: other.leads || null,
    ftds: other.ftds || null,
    countries: other.countries || null,
    netDeposits: other.netDeposits || null,

  }
}

<template>
  <div>

    <b-table responsive
             v-bind:items="statusMapItems"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:busy="loading"
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >

      <template #cell(status)="data">
        <span :style="{color: statusOptions[data.value].color}"> {{statusOptions[data.value].name }}</span>
      </template>


    </b-table>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import { vue } from '@/main';

export default {
  name: 'StatusDashboardTable',
  components: {},
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    totalByCountry: {},
  }),
  props: {
    value: {
      required: true,
    },
    lazy: {
      default: () => false
    },
  },

  created() {

  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),
    ...mapGetters('clients', ['allStatuses']),

    statusMapItems() {
      return Object.entries(this.value)
          .map(([status, count]) => ({
            status,
            count
          }));
    },
    statusOptions() {
      return this.allStatuses.reduce((map, status) => {
        map[status.id] = {
          name: status.status,
          color: status.color
        };
        return map;
      }, {});
    },
    fields() {
      return [
        {
          key: 'status',
          label: this.$t('columns.status'),
          sortable: true,
          active: true,
        },
        {
          key: 'count',
          label: this.$t('columns.count'),
          sortable: true,
          active: true,
        }
      ];
    },
  },
  methods: {

    combineCountryTotals(affiliatesData) {
      const combinedMap = {};

      // Iterate over each item in the items array
      affiliatesData.forEach(item => {
        Object.entries(item.countries)
            .forEach(([country, count]) => {
              // If the country already exists in the combined map, add to its count
              if (combinedMap[country]) {
                combinedMap[country] += count;
              } else {
                // If the country does not exist, create a new entry in the map
                combinedMap[country] = count;
              }
            });
      });

      return combinedMap;
    }
  },
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}

.color-badge {
  display: inline-block;
  width: 15rem;
  height: 1rem;
}
</style>
